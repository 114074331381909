.place {
    margin: 0 auto;
}

.place__container {
    background-image: url(../../images/place__logo.svg);
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 1600px;
    height: 485px;
    margin: 0 auto;
    border-top: 0.1px solid #2D303F;
    border-bottom: 0.1px solid #2D303F;
    border-left: none;
    border-right: none;
    display: flex;
    padding-left: 130px;
}

@media screen and (max-width: 630px) {
    .place__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}

.place__wrap {
    display: flex;
    flex-direction: column;
    width: 340px;
    align-items: center;
    justify-content: center;
}

.place__title {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    margin: 0 0 20px 0;
    text-align: center;
}

.place__link {
    text-decoration: none;
}

.place__button {
    width: 100px;
    height: 43px;
    background-color: #6A4DDD;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    border: none;
    border-radius: 8px;
    margin-right: 16px;
    cursor: pointer;
}

.place__button:hover {
    transform: scale(1.03);
    transition: linear 0.3s;
}