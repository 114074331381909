.footer {
  /* position: absolute; */
  /* max-width: 1320px; */
  /* bottom: 0;
  left: 0;
  right: 0; */
  margin: 0 auto;
  padding: 28px 0 46px;
}

.footer__container {
  max-width: 1320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px 0;
}

.footer__wrap {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 555px) {
  .footer__wrap:first-of-type {
    display: none;
  }
}

.footer__image {
  width: 24px;
  height: 24px;
}

.footer__link {
  text-decoration: none;
  transition: linear 0.4s;
  margin: 0 16px 0 0;
}

@media screen and (max-width: 460px) {
  .footer__link {
    margin: 0 8px 0 0;
  }
}

@media screen and (max-width: 420px) {
  .footer__link {
    margin: 0 4px 0 0;
  }
}

.footer__link:hover {
  fill: #6a4ddd;
}

.footer__link:last-of-type {
  margin: 0;
}

.footer__links {
  text-decoration: none;
}

.footer__developer {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #8e91a7;
  margin: 0;
  cursor: pointer;
}

.footer__developer:hover {
  transition: linear 0.4s;
  color: #6a4ddd;
}

@media screen and (max-width: 775px) {
  .footer__developer {
    font-size: 12px;
    line-height: 14px;
  }
}

.footer__link {
  text-decoration: none;
}

.footer__contacts {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  margin: 0;
}

@media screen and (max-width: 775px) {
  .footer__contacts {
    font-size: 13px;
    line-height: 16px;
  }
}

.footer__consul {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  margin: 0 0 0 30px;
}

@media screen and (max-width: 775px) {
  .footer__consul {
    font-size: 13px;
    line-height: 16px;
    margin: 0 0 0 20px;
  }
}

.footer__contacts:hover {
  transition: linear 0.4s;
  color: #ffffff;
}

.footer__consul:hover {
  transition: linear 0.4s;
  color: #ffffff;
}
