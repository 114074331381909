@import url("../../vendor/fonts/fonts.css");

.page {
  font-family: "Inter", "Arial", "Helvetica", sans-serif;
  margin: 0 auto;
  max-width: 1600px;
  min-height: 100vh;
}

.container {
  background-color: #12131a;
}
