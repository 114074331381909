.product {
  margin: 0 auto 92px;
}

.product__container {
  padding-bottom: 92px;
  border-bottom: 1px solid #22242f;
}

.product__wrap_title {
  display: flex;
  justify-content: space-between;
  margin: 0 0 46px;
}

.product__title {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 0;
}

.product__logo
{
  max-height: 20px;
}

.product__button_title {
  background-color: #23242d;
  border-radius: 8px;
  width: 99px;
  height: 43px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  border: none;
  cursor: pointer;
}

.product__button_title:hover {
  background-color: #31333f;
  transition: linear 0.3s;
}

.product__wrap_grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 318px);
  grid-gap: 16px;
  max-width: 1320px;
  justify-content: center;
}

@media screen and (max-width: 1345px) {
  .product__wrap_grid {
    padding: 0 20px 0;
  }
}

.product__link {
  text-decoration: none;
}

/* old card styles */
/* .product__wrap {
    width: 318px;
}

.product__wrap:hover {
    transform: scale(0.99);
    transition: linear 0.2s;
}

.product__wrapper {
    background-color: #16171E;
    border-radius: 12px;
} */

/* current styles for card */
.product__wrap {
  flex-flow: column;
  width: 318px;
  justify-content: flex-end;
  background-size: 110%;
  border-radius: 12px;
  background-position: top center;
  display: flex;
  height: 370px;
  overflow: hidden;
  background-repeat: no-repeat;
  transition: 0.2s;
}

.product__wrap:hover {
  background-size: 120%;
}
/* .games__wrap:hover .games__wrapper {
    background: rgba(22, 23, 30, 0.7);
  } */

.product__wrapper {
  background: rgba(22, 23, 30, 0.6);
  /* transition: linear 0.2s; */
  backdrop-filter: blur(30px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.product__image {
  width: 318px;
  height: 190px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.product__wrapper_width {
  margin: 18px 0 0 24px;
  width: 270px;
}

.product__wrapper_title-wrap {
  padding-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.product__wrapper_title-text {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  letter-spacing: 0.015em;
  color: #ffffff;
  margin: 0;
}

.product__wrapper_title-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.product__logo_title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: #9296a9;
  margin: 0 0 0 4px;
}

.product__wrapper_product {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #22242f;
  padding-bottom: 16px;
}

.product__wrapper_title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: #ffffff;
  margin: 0;
}

.product__wrapper_product_box {
  display: flex;
}

.product__wrapper_logo {
  margin-right: 5px;
}

.product__wrapper_price {
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product__wrapper_subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.015em;
  color: #ffffff;
  margin: 0;
}

.product__wrapper_subtitle-span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: #9296a9;
  margin: 0;
}

.product__button {
  margin: 24px 0 18px 0;
  width: 270px;
  height: 43px;
  background-color: #23242d;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  border: none;
  cursor: pointer;
}

.product__link:hover .product__button {
  transition: linear 0.4s;
  border: none;
  background-color: #6a4ddd;
  /* background-color: #31333F; */
  color: white;
}
