.risk {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fe8d3b;
}

.risk__circle {
  width: 6px;
  height: 6px;
  background: #fe8d3b;
  border-radius: 50%;
  margin-right: 6px;
}

.risk__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: #fe8d3b;
  margin: 0;
}

.undetected {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00be88;
}

.undetected__circle {
  width: 6px;
  height: 6px;
  background: #00be88;
  border-radius: 50%;
  margin-right: 6px;
}

.undetected__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: #00be88;
  margin: 0;
}

.update {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2aabd4;
}

.update__circle {
  width: 6px;
  height: 6px;
  background: #2aabd4;
  border-radius: 50%;
  margin-right: 6px;
}

.update__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
  color: #2aabd4;
  margin: 0;
}
