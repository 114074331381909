.header {
  max-width: 1320px;
  margin: 0 auto;
}

.header__container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1360px) {
  .header__container {
    margin: 0 20px 0 20px;
  }
}

@media screen and (max-width: 560px) {
  .header__container {
    margin: 0 15px 0 15px;
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
}

.header__logo {
  background-image: url(../../images/header__logo.svg);
  width: 50px;
  height: 56px;
  margin: 16px 32px 16px 0;
}

.header__link {
  text-decoration: none;
  margin-right: 32px;
}

.header__catalog {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  cursor: pointer;
}

.header__catalog:hover {
  transition: linear 0.4s;
  color: #ffffff;
}

@media screen and (max-width: 560px) {
  .header__support {
    font-size: 14px;
    line-height: 17px;
  }
}

@media screen and (max-width: 420px) {
  .header__support {
    font-size: 13px;
    line-height: 16px;
  }
}

@media screen and (max-width: 560px) {
  .header__catalog {
    font-size: 14px;
    line-height: 17px;
    margin: 0 16px 0 26px;
  }
}

@media screen and (max-width: 420px) {
  .header__catalog {
    font-size: 13px;
    line-height: 16px;
    margin: 0 12px 0 22px;
  }
}

.header__support:hover {
  transition: linear 0.4s;
  color: #ffffff;
}

.header__wrap {
  display: flex;
  align-items: center;
}

.header__wrap:nth-child(2) {
  margin: 0 32px 0 32px;
}

.header__logo_language {
  background-image: url(../../images/header__logo_language.svg);
  width: 19px;
  height: 19px;
  margin-right: 5px;
}

.header__button {
  background: none;
  border: none;
  color: white;
}

.header__logo_purchases {
  background-image: url(../../images/header__logo_purchases.svg);
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.header__purchases {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  margin: 0;
}

.header__logo_search {
  background-image: url(../../images/header__logo_search.svg);
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.header__search {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  margin: 0;
}

@media screen and (max-width: 640px) {
  .header__translate {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 10px;
  }
}

@media screen and (max-width: 560px) {
  .header__translate {
    font-size: 14px;
    line-height: 17px;
  }
}

.header__language {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.header__language_select {
  margin-left: 5px;
  cursor: pointer;
}

.header__translate {
  background-color: #12131a;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  color: #c2c6d7;
  margin: 0 0 0 10px;
  cursor: pointer;
  padding: 0;
}

.header__translate_active {
  color: #ffffff;
}

/* .header__translate_eng {
    background-color: #12131a;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.015em;
    color: #ffffff;
    margin: 0 40px 0 10px;
    cursor: pointer;
    padding: 0;
  } */

@media screen and (max-width: 560px) {
  .header__translate_ru {
    font-size: 14px;
    line-height: 17px;
  }
}

.header__translate_ru:hover {
  color: #c2c6d7;
  transition: linear 0.3s;
}

.header__translate_eng:hover {
  color: #c2c6d7;
  transition: linear 0.3s;
}

@media screen and (max-width: 640px) {
  .header__wrapper_links {
    display: none;
  }
}
