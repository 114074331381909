.faq {
  margin: 0 auto 92px;
  height: 100%;
}

@media screen and (max-width: 690px) {
  .games {
    margin: 0 auto 40px;
  }
}

.faq__container {
  padding-bottom: 92px;
  border-bottom: 1px solid #22242f;
  max-width: 1320px;
  margin: 0 auto;
}

.faq__wrap_grid {
  column-gap: 20px;
  row-gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.faq__card_container {
}

.faq__wrap {
  flex-flow: column;
  justify-content: flex-end;
  background-size: 110%;
  border-radius: 12px;
  background-position: top center;
  display: flex;
  /* height: 370px; */
  background-color: #22242f;
  overflow: hidden;
  background-repeat: no-repeat;
  transition: 0.3s;
}

.faq__wrap:hover {
  background-size: 115%;
}
/* .games__wrap:hover .games__wrapper {
      background: rgba(22, 23, 30, 0.7);
    } */

.faq__wrapper {
  background: rgba(22, 23, 30, 0.6);
  /* transition: linear 0.2s; */
  backdrop-filter: blur(30px);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.faq__inner_container {
  padding: 10px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
}

.faq__driver_title {
  color: #9fa4b0;
  line-height: 24px;
}

.faq__driver_subtitle {
  color: #ffffff;
  font-size: 20px;
  line-height: 24px;
}

.faq__button {
  margin: 24px 0 18px 0;
  width: 200px;
  height: 43px;
  background-color: #23242d;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  transition: linear 0.2s;
  color: #c2c6d7;
  border: none;
  cursor: pointer;
}

.faq__button:hover {
  border: none;
  background-color: #6a4ddd;
  /* background-color: #31333F; */
  color: white;
}

@media screen and (max-width: 1345px) {
  .faq__container {
    padding: 0 20px 40px;
  }
}

.games__wrap_title {
  display: flex;
  justify-content: space-between;
  margin: 0 0 46px;
}

.games__title {
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin: 0;
}

@media screen and (max-width: 575px) {
  .games__title {
    font-size: 34px;
  }
}

@media screen and (max-width: 475px) {
  .games__title {
    font-size: 24px;
  }
}
