.notfound {
    margin: 0 auto;
}

.notfound__container {
    background-image: url(../../images/notfound.svg);
    height: 707px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 0.1px solid #2D303F;
    border-bottom: 0.1px solid #2D303F;
}

.notfound__title {
    font-style: normal;
    font-weight: 800;
    font-size: 150px;
    line-height: 182px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    margin: 0 0 10px 0;
}

.notfound__subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    margin: 0 0 30px 0;
}

.notfound__button {
    width: 120px;
    height: 50px;
    background-color: #6A4DDD;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.notfound__link {
    text-decoration: none;
}